// 格式化日期
export function formatDate(date, fmt) {
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

export const ResponseCode =
  {
    ServerError : 1000,
    NeedLogin : 401,
    NeedKey : 1010,
    InviteCodeNotFound : 1012,
    InviteCodeUsed : 1013,
    InviteCodeUsedOther : 1014,
    HaveKey : 1011,
    AccountNotFound : 1002,
    CheckCodeNotFound : 1008,
    UpgradeKeyNotFound : 1015,
    UpgradeKeyIsUsed : 1016,
  }

<template>
  <div id="domain">
    <div class="title">
      <h1>{{ $t("domain.title") }}</h1>
      <br>
      <p class="mb-0">{{ $t("domain.subtitle") }}</p>
    </div>
    <div id="galaxy">
      <map name="image-map">
        <area @click="onDomainClick" target="" alt="" title="1" href="javascript:void(0)" coords="1583,1299,1583,1329,1613,1340,1632,1310,1613,1291" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="2" href="javascript:void(0)" coords="1501,1214,1504,1260,1490,1299,1479,1359,1517,1392,1547,1348,1547,1291,1599,1263,1602,1228,1534,1206" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="3" href="javascript:void(0)" coords="1586,1362,1553,1417,1561,1460,1616,1504,1662,1455,1706,1416,1651,1359,1624,1381" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="4" href="javascript:void(0)" coords="1657,1230,1646,1263,1673,1296,1673,1332,1728,1381,1832,1411,1851,1378,1755,1367,1736,1299,1739,1258,1695,1219" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="5" href="javascript:void(0)" coords="1599,1077,1588,1162,1624,1195,1684,1187,1777,1214,1876,1228,1919,1206,1911,1132,1908,1096,1818,1064,1733,1042,1676,1085,1627,1064" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="6" href="javascript:void(0)" coords="1189,979,1203,1050,1263,1085,1277,1118,1296,1143,1296,1176,1383,1187,1413,1230,1460,1304,1471,1241,1471,1187,1526,1167,1575,1195,1564,1083,1493,1105,1444,1050,1337,998,1241,962" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="7" href="javascript:void(0)" coords="1394,1252,1329,1334,1356,1378,1331,1444,1359,1479,1351,1548,1405,1558,1438,1619,1564,1695,1646,1676,1703,1673,1742,1589,1676,1493,1610,1553,1515,1438,1444,1383" shape="poly">
        <area @click="onDomainClick" target="" alt="" title="8" href="javascript:void(0)" coords="1763,1249,1771,1293,1766,1347,1851,1339,1881,1358,1870,1413,1848,1446,1733,1420,1706,1458,1766,1549,1782,1613,1777,1678,1895,1664,1949,1604,1993,1544,2039,1506,2031,1438,2004,1391,1933,1347,1875,1301,1867,1257,1815,1263" shape="poly">
      </map>
      <b-img usemap="#image-map" src="./galaxy_01.webp" fluid></b-img>
      <div v-if="selectedDomain > 0" class="galaxy-mask">
        <b-img :src="`../galaxy_Mask_0${selectedDomain}.png`" fluid></b-img>
      </div>
      <div class="domain-des p-4 scroll-to-me" v-if="selectedDomain > 0">
        <h3>{{ $t("domain.domains")[selectedDomain].name }}</h3>
        <p class="mx-3">{{ $t("domain.domains")[selectedDomain].desc }}</p>
        <p>{{territoryInfo }}</p>
        <b-button class="button" pill @click="onSubmitClick" >{{ $t("domain.submit") }}
        </b-button>
        <p>{{ hint }}</p>
      </div>
    </div>
    <b-link class="link" to="/friend">
      <!-- <b-img src="../Friend/friend_bg_1.webp" fluid></b-img> -->
      <div id="invite">
        <div id="text">
          <p>Are you willing to fight with your loved ones among the stars?</p>
          <p>Do you want exclusive rewards?</p>
          <p>Click the button below and join our Comrade-In-Arms recruitment program immediately!</p>
        </div>
      </div>
    </b-link>

  </div>
</template>

<script>
import axios from 'axios';
import ImageMap from "image-map";

export default {
  data () {
    return {
      selectedDomain: 0,
      hint: '',
      territoryInfo: 'Remaining',
    };
  },
  mounted(){
    ImageMap('img[usemap]',500);
  },
  methods: {
    onDomainClick (e) {
      this.selectedDomain = e.srcElement.title
      this.hint = ''
      this.territoryInfo = 'Remaining'
      if(!this.selectedDomain>0) return
      this.$nextTick(()=>this.scrollToElement())
      axios.get(`${process.env.VUE_APP_SERVER_FUR}api/territory@1.0/${this.selectedDomain}`)
        .then((res)=>{
          const {data} = res
          if(data.data){
            this.territoryInfo = `Remaining ${data.data.total-data.data.used}/${data.data.total}`
          }
          else{
            this.territoryInfo = `Remaining ${data.reason}`
          }
        })
    },
    onSubmitClick () {
      this.hint = 'Domain Function is Stop.'
      return;
      // if(!localStorage.token){
      //   this.$emit('showLoginForm')
      //   return
      // }
      // axios.post(`${process.env.VUE_APP_SERVER}seizeTerritory`, {id: this.selectedDomain, token: localStorage.token})
      //   .then((res) => {
      //     const { data } = res
      //     if(data.code == 1002){
      //       this.$emit('showKeyForm')
      //     }
      //     else if(data.code == 1001){
      //       this.$emit('showLoginForm')
      //     }
      //     else if(data.playerTerritory){
      //       this.hint = `You already have territory: ${this.$t("domain.domains")[data.playerTerritory.territoryId].name}!`
      //     }
      //     else if(data.reason){
      //       this.hint = data.reason
      //     }
      //     else if(data.territory){
      //       this.hint = "Success!"
      //       this.territoryInfo = `Remaining ${data.territory.total-data.territory.used}/${data.territory.total}`
      //     }
      //     // this.selectedDomain = 0;
      //   })
      //   .catch(()=>{
      //     this.hint = 'Wrong Key!'
      //   })
    },
    scrollToElement() {
      const el = this.$el.getElementsByClassName('scroll-to-me')[0];
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'})
      }
    }
  },
};
</script>

<style lang="scss">
#domain {
  background-color: $custom-bg-color;
  color: white;
  white-space: pre-line;

  area{
    outline: none;
  }
  .title{
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding: 2rem 0 0;
    max-width: 780px;
    z-index: 100;
  }
  #galaxy {
    z-index: 99;
    position: relative;
    top: -10rem;
    bottom: -10rem;
    .galaxy-mask{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      pointer-events: none;
    }
  }
  #invite{
    position: relative;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../Friend/friend_bg_1.webp");
    background-size: cover;
    background-position: center;
    height: 400px;
    #text{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      height: 30%;
      margin: auto;
    }
  }
  .button {
    background: #f37a00;
    color: black;
    width: 10rem;
  }
  .input {
    display: block;
    outline-width: 0;
    outline-style: none;
    width: 20rem;
    margin: 1em auto;
    border: 2px solid #f37a00;
    border-radius: 2em;
    background: $custom-box-color;
    padding: 10px 20px;
    color: $custom-text-color;
  }
  .domain-des {
    z-index: 100;
    position: absolute;
    // top: -15rem;
    bottom: -10rem;
    left: 0;
    right: 0;
    background: $custom-box-color;
    border-radius: 2em;
    max-width: 780px;
    margin: 0 auto;
  }
  .pop {
    background: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    z-index: 101;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    .confirm {
      position: relative;
      margin: auto;
      max-width: 780px;
      background: $custom-box-color;
      border-radius: 2em;
      .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #f37a00;
      }
    }
  }
  .link{
    // text-decoration: none;
    color: white;
  }
}
</style>

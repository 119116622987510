<template>
  <div id="gamePack" class="justify-content-center text-center">
    <div class="title h-100 justify-content-center text-center">
      <h1>{{ $t("gamePack.title") }}</h1>
      <br>
      <!-- <p class="mb-0">{{ $t("GamePack.subtitle") }}</p> -->
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
    };
  },
  mounted(){
  },
  methods: {

  },
};
</script>

<style lang="scss">
#gamePack {
  background-color: $custom-bg-color;
  color: white;
  white-space: pre-line;
  height: 80vh;

  .title{
    position: relative;
    width: 80%;
    margin: auto;
    max-width: 780px;
    z-index: 100;
    display: flex;
    flex-direction: column;
  }
  .button {
    background: #f37a00;
    color: black;
    width: 10rem;
  }
}
</style>

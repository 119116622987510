<template>
  <div id="friend">
    <div id="header">
      <h5>Does our game make you happy?</h5>
      <h5>If it does I hope you can recommend it to your family and friends!</h5>
      <h5>If it doesn't recommend it to people you don't like!</h5>
      <h1 class="mt-5">Comrade in arms / recruit a friend</h1>
      <h5 class="mt-5">Epic rewards await you and your friends!</h5>
    </div>
    <div id="gift">
      <h2 id="title">——————  Recruitment rewards  ——————</h2>
      <p>Every time one of your friends joins the fray you will earn unique rewards!</p>
      <p>When your friend joins the game, they will also receive the tier 1 reward!</p>
      <b-container class="mt-5">
        <b-row>
          <b-col cols=4>
            <div class="lock" v-if="inviteNumber < 1">
              <p>Recruit {{1-inviteNumber}} more {{ (1-inviteNumber == 1)?"friend":"friends"}}  to unlock!</p>
              <b-img class="gift-img" src="./friend_1_lock.webp"></b-img>
            </div>
            <div class="unlock" v-else>
              <p class="yellow">1 Friend playing thanks to you</p>
              <b-img class="gift-img" src="./friend_1.webp"></b-img>
            </div>
            <p class="yellow">Pet: Catstronault</p>
            <p>An American Shorthair cat with a full space suit,
always following you
helping you pick up your loot
Litterbox not included</p>
            <p class="yellow">Title: The one with friends</p>
          </b-col>
          <b-col cols=4>
            <div class="lock" v-if="inviteNumber < 2">
              <p>Recruit {{2-inviteNumber}} more {{ (2-inviteNumber == 1)?"friend":"friends"}} to unlock!</p>
              <b-img class="gift-img" src="./friend_2_lock.webp"></b-img>
            </div>
            <div class="unlock" v-else>
              <p class="yellow">2 Friends playing thanks to you</p>
              <b-img class="gift-img" src="./friend_2.webp"></b-img>
            </div>
            <p class="yellow">Hoverbike skin: Primitive sidecar</p>
            <p>A retro yet cool type of bike that can transport two people</p>
          </b-col>
          <b-col cols=4>
            <div class="lock" v-if="inviteNumber < 3">
              <p>Recruit {{3-inviteNumber}} more {{ (3-inviteNumber == 1)?"friend":"friends"}} to unlock!</p>
              <b-img class="gift-img" src="./friend_3_lock.webp"></b-img>
            </div>
            <div class="unlock" v-else>
              <p class="yellow">3 Friends playing thanks to you</p>
              <b-img class="gift-img" src="./friend_3.webp"></b-img>
            </div>
            <p class="yellow">Furniture：convivial toilet</p>
            <p>Never feel alone in the bathroom again,these automatically flush when you get up</p>
          </b-col>
          <b-col cols=2></b-col>
          <b-col cols=4>
            <div class="lock" v-if="inviteNumber < 4">
              <p>Recruit {{4-inviteNumber}} more {{ (4-inviteNumber == 1)?"friend":"friends"}} to unlock!</p>
              <b-img class="gift-img" src="./friend_4_lock.webp"></b-img>
            </div>
            <div class="unlock" v-else>
              <p class="yellow">4 Friends playing thanks to you</p>
              <b-img class="gift-img" src="./friend_4.webp"></b-img>
            </div>
            <p class="yellow">Exoskeleton: F.R.1.3.N.D.5</p>
            <p>I will carry my friends!</p>
            <p>Litteraly!(or their portraits at least)</p>
          </b-col>
          <b-col cols=4>
            <div class="lock" v-if="inviteNumber < 5">
              <p>Recruit {{5-inviteNumber}} more {{ (5-inviteNumber == 1)?"friend":"friends"}} to unlock!</p>
              <b-img class="gift-img" src="./friend_5_lock.webp"></b-img>
            </div>
            <div class="unlock" v-else>
              <p class="yellow">5 Friends playing thanks to you</p>
              <b-img class="gift-img" src="./friend_5.webp"></b-img>
            </div>
            <p class="yellow">Waifu: the best bro</p>
            <p>A waifu with tig ol' bitties that acts like a bro</p>
            <p>Dresses neutraly</p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div id="invite">
      <h1 class="mt-5">I'm ready! How do I recruit friends?</h1>
      <p class="w-50 mx-auto">After a loved one gets a copy of our game
They simply need to register their accounts on the space territory assigment website
Enter your invitation code (featured below) when they are prompted to do so
And you can enjoy your rewards!</p>
      <div id="player-code">
        <input
          v-model="inviteCode"
          class="input d-inline"
          placeholder="NEED LOGIN"
          readonly
        />
        <b-button class="button copy-button" pill @click="onCodeCopy">COPY</b-button>
      </div>

      <p class="w-50 mx-auto">You will receive your rewards within 24 hours
once the recruitment is successful.
A little bit of patience and you'll unwrap your gifts!</p>
      <p class="w-50 mx-auto mt-5">If your loved ones missed the opportunity to type in the invitation code
You can have them log in to their account and come to this very page
They can enter your code to grant both of your rewards below! </p>
      <div id="player-code">
        <input
          v-model="inviteCodeInput"
          class="input d-inline"
          placeholder="ENTER CODE"
        />
        <b-button class="button copy-button" pill @click="onCodeSubmit" :disabled="inviteCodeButtonDisabled"><b-icon icon="arrow-right"></b-icon></b-button>
      </div>
      <h2>{{inviteHint}}</h2>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { ResponseCode } from '../../util/util'
export default {
  data () {
    return {
      inviteCode: "",
      inviteCodeInput: "",
      inviteCodeButtonDisabled: true,
      inviteNumber: 0,
      inviteHint: "",
    };
  },
  computed: {
    ...mapState(['isLogin','isUsedKey']),
  },
  methods: {
    onCodeCopy(){
      navigator.clipboard.writeText(this.inviteCode)
    },
    onCodeSubmit(){
      this.inviteHint = "This Function is Stop"
      return
      // if(!localStorage.token){
      //   this.$emit('showLoginForm')
      // }
      // else{
      //   axios.post(`${process.env.VUE_APP_SERVER}useInviteCode`, {token: localStorage.token, code: this.inviteCodeInput})
      //     .then((res) => {
      //       const { data } = res
      //       if(data.code == 1001){
      //         this.$emit('showLoginForm')
      //       }
      //       else if(data.code == 1002){
      //         this.$emit('showKeyForm')
      //       }
      //       else if(data.code == 1003){
      //         this.inviteHint = "Code is not found!"
      //       }
      //       else if(data.code == 1004){
      //         this.inviteHint = "You are invited! Can't use code."
      //       }
      //       else if(data.code == 1005){
      //         this.inviteHint = "Your friend used your code, you can't use his/her code."
      //       }
      //       else if(data.inviteCode){
      //         this.inviteCode = data.inviteCode.code
      //         this.inviteNumber = data.inviteCode.used
      //       }
      //     })
      // }
    },
    findInviteCode(){
      if(localStorage.token){
        axios.get(`${process.env.VUE_APP_SERVER_FUR}api/invite@1.0`)
          .then((res) => {
            const { data } = res
            if(data.succeeded){
              this.inviteCode = data.data.inviteCode.code
              this.inviteNumber = data.data.inviteCode.used
              this.inviteCodeButtonDisabled = false
              if(data.data.useInviteCode){
                this.inviteCodeButtonDisabled = true
                this.inviteHint = "You are invited! Can't use code anymore."
              }
            }
            else if(data.statusCode == ResponseCode.NeedKey){
              this.$emit('showKeyForm')
            }
          })
        .catch(error=>{
          if(error.response.status == 401){
            this.$emit('showLoginForm')
          }
        })
      }
    },
  },
  watch: {
    isLogin(newVal){
      if(newVal){
        this.findInviteCode()
      }
      else{
        Object.assign(this.$data, this.$options.data())
      }
    },
    isUsedKey(newVal){
      if(newVal){
        this.findInviteCode()
      }
    }
  },
  mounted(){
    if(this.isLogin){
      this.findInviteCode()
    }
  },
}
</script>

<style lang="scss">
#friend {
  background-color: $custom-bg-color;
  color: white;
  white-space: pre-line;
  #header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./friend_bg_1.webp");
    background-size: cover;
    height: 40rem;
    padding-top: 10rem;
  }
  #gift {
    background: black;
    position: relative;
    #title{
      color: rgb(255,220,172)
    }
    .gift-img{
      width: 100%;
    }
    .yellow{
      color: rgb(255, 192, 0);
    }
  }
  #invite{
    padding: 6rem 0;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./friend_bg_2.webp");
    #player-code{
      position: relative;
      width: 20rem;
      margin: auto;
      .copy-button{
        position: absolute;
        height: 2.5rem;
        width: 4rem;
        right: 0.3rem;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
  .input {
    display: block;
    outline-width: 0;
    outline-style: none;
    width: 20rem;
    margin: 1em auto;
    border: 2px solid #f37a00;
    border-radius: 2em;
    background: $custom-box-color;
    padding: 10px 20px;
    color: $custom-text-color;
  }
  .button {
    background: #f37a00;
    color: black;
    width: 10rem;
  }
}
</style>

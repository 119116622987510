<template>
  <div id="app">
    <!-- 导航栏 -->
    <b-navbar id="nav" toggleable="md" type="dark" variant="dark" class="py-0" sticky>
      <b-navbar-brand to="/">
        <b-img id="logo" src="@/assets/logo-efas-en.png" class="ml-4"></b-img>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <!-- <b-nav-item to="/friend">Friend</b-nav-item> -->
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item @click="onNavLogin" v-if="!isLogin"  class="mr-4">{{ $t("navbar.login") }}</b-nav-item>
          <b-nav-item-dropdown class="ml-auto account-dropdown" :text="this.login.account" v-if="isLogin" right>
            <!-- <b-dropdown-item>
              <b-button class="button" pill @click="onNavGift"> {{ $t("navbar.gift") }} </b-button>
            </b-dropdown-item> -->
            <!-- <b-dropdown-item>
              <b-button class="button" pill @click="onNavInputKey"> {{ $t("navbar.inputKey") }} </b-button>
            </b-dropdown-item> -->
            <b-dropdown-item>
              <b-button class="button" pill @click="onNavLogout"> {{ $t("navbar.logout") }} </b-button>
            </b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <!-- 登录 -->
    <div id="login-pop" class="pop" v-if="!isLogin">
      <div class="confirm p-4">
        <!-- <b-icon-x-circle class="close" @click="loginForm.show = false"></b-icon-x-circle> -->
        <h3>{{ $t("navbar.login") }}</h3>
        <h5>{{ $t("navbar.loginHint") }}</h5>
        <input
          v-model="loginForm.email"
          class="input"
          :placeholder="$t('navbar.email')"
        />
        <input
          v-model="loginForm.password"
          class="input"
          type="password"
          :placeholder="$t('navbar.password')"
        />
        <p>{{ loginForm.hint }}</p>
        <b-button class="button" pill @click="onLoginSubmit"> {{$t("domain.submit")}} </b-button>
        <br>
        <b-button class="button mt-1" pill @click="loginForm.show = false; resetForm.show = true;"> Reset Password </b-button>
      </div>
    </div>

    <!-- 重置密码 -->
    <div id="reset-pop" class="pop" v-if="resetForm.show">
      <div class="confirm p-4">
        <b-icon-x-circle class="close" @click="resetForm.show = false"></b-icon-x-circle>
        <h3>Reset Password</h3>
        <h5>Please enter your email, your desired new password and verify your identity with the verification code sent to your email in order to resent your password.</h5>
        <input
          v-model="resetForm.email"
          class="input"
          placeholder="Email"
        />
        <input
          v-model="resetForm.password"
          class="input"
          type="password"
          placeholder="New password"
        />
        <div id="player-code">
          <input
            v-model="resetForm.code"
            class="input"
            placeholder="Verification Code"
          />
          <b-button class="button copy-button" pill @click="onSendEmail">Send</b-button>
        </div>
        <p>{{ resetForm.hint }}</p>
        <br>
        <b-button class="button" pill @click="onResetSubmit">Confirm</b-button>
      </div>
    </div>

    <!-- 激活码 -->
    <div id="key-pop" class="pop" v-if="keyForm.show">
      <div class="confirm p-4">
        <b-icon-x-circle class="close" @click="keyForm.show = false"></b-icon-x-circle>
        <h3>{{ $t("key.title") }}</h3>
        <h5>{{ $t("key.des") }}</h5>
        <input
          v-model="keyForm.key"
          class="input"
          :placeholder="$t('key.input')"
        />
        <p>{{ keyForm.hint }}</p>
        <b-button class="button" pill @click="onKeySubmit"> {{$t("domain.submit")}} </b-button>
      </div>
    </div>
    <!-- 礼物 -->
    <div id="gift-pop" class="pop" v-if="isLogin">
      <div class="confirm p-4">
        <!-- <b-icon-x-circle class="close" @click="giftForm.show = false"></b-icon-x-circle> -->
        <h3>{{ $t("gift.title") }}</h3>
        <h5>{{ $t("gift.des") }}</h5>
        <b-container>
          <b-row>
            <b-col v-for="gift in giftForm.gifts" :key="gift.id" class="bg-dark"  lg="4">
              <h6 class="d-inline">{{gift.name}}</h6>
              <!-- <p class="m-0">{{gift.desc}}</p> -->
            </b-col>
          </b-row>
        </b-container>
        <p class="mt-2">Click the button below if you have an Activation Code to redeem.</p>
        <p>(Upgrades available on <a href="https://earthfromanothersun.net/pages/upgrades" target="_blank">earthfromanothersun.net/pages/upgrades</a>)</p>
        <p>(Christmas bundle available on <a href="https://earthfromanothersun.net/pages/efasmas" target="_blank">earthfromanothersun.net/pages/efasmas</a>)</p>
        <b-button class="button copy-button mt-2" pill @click="onUpgradeClick">Redeem Activation Code</b-button>
      </div>
    </div>
    <!-- 升级包激活 -->
    <div id="key-pop" class="pop" v-if="upgradeKeyForm.show">
      <div class="confirm p-4">
        <b-icon-x-circle class="close" @click="upgradeKeyForm.show = false"></b-icon-x-circle>
        <h3></h3>
        <h5>Enter your Activation Code here and we'll do the rest!</h5>
        <input
          v-model="upgradeKeyForm.key"
          class="input"
          placeholder="UPGRADE"
        />
        <p>{{ upgradeKeyForm.hint }}</p>
        <b-button class="button" pill @click="onUpgradeKeySubmit"> UPGRADE </b-button>
      </div>
    </div>

    <!-- <router-view @showLoginForm="showLoginForm" @showKeyForm="showKeyForm"/> -->
  </div>
</template>

<style lang="scss">
#app {
  font-family: GraphikLC, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: $custom-bg-color;
  color: white;

  #nav {
    #logo {
      max-width: 10rem;
    }
    .account-dropdown {
      ul {
        background-color: $dark;
        a:hover {
          background-color: $dark;
          cursor: auto;
        }
      }
    }
  }
  .button {
    background: #f37a00;
    color: black;
    width: 10rem;
  }
  .input {
    display: block;
    outline-width: 0;
    outline-style: none;
    width: 20rem;
    margin: 1em auto;
    border: 2px solid #f37a00;
    border-radius: 2em;
    background: $custom-box-color;
    padding: 10px 20px;
    color: $custom-text-color;
  }
  .pop {
    background: rgba($color: #000000, $alpha: 0.4);
    position: fixed;
    z-index: 101;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    .confirm {
      position: relative;
      margin: auto;
      max-width: 780px;
      background: $custom-box-color;
      border-radius: 2em;
      .close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        color: #f37a00;
      }
    }
  }

  #player-code{
    position: relative;
    width: 20rem;
    margin: auto;
    .copy-button{
      position: absolute;
      height: 2.5rem;
      width: 4rem;
      right: 0.3rem;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
}
</style>

<script>
import axios from 'axios';
import {mapMutations, mapState} from 'vuex';
import { ResponseCode } from "./util/util.js";
export default {
  data() {
    return {
      loginForm: {
        show: false,
        email: '',
        password: '',
        hint: '',
      },
      keyForm: {
        show: false,
        key: '',
        hint: '',
      },
      giftForm: {
        show: false,
        gifts: null,
      },
      login: {
        account: '',
      },
      resetForm: {
        show: false,
        email: '',
        password: '',
        code: '',
        hint: '',
      },
      upgradeKeyForm: {
        show: false,
        key: '',
        hint: '',
      },
    }
  },
  computed: {
    ...mapState(['isLogin','isUsedKey']),
  },
  methods: {
    ...mapMutations(['changeLogin','changeUsedKey']),
    onNavLogin() {
      this.showLoginForm()
    },
    onNavInputKey() {
      this.showKeyForm()
    },
    onNavLogout() {
      localStorage.removeItem('token')
      Object.assign(this.$data, this.$options.data())
      this.changeLogin(false)
      this.changeUsedKey(false)
    },
    onNavGift() {
      axios.get(`${process.env.VUE_APP_SERVER_FUR}api/gift@1.0`)
        .then((res) => {
          const { data } = res
          if(data.succeeded){
            this.giftForm.show = true
            this.giftForm.gifts = new Array();
            if (!data.data) return;
            data.data.forEach(element => {
              this.giftForm.gifts.push({id: element.playerGift.id, name: element.gift.name})
            });
            axios.get(`${process.env.VUE_APP_SERVER_FUR}api/territory@1.0`)
              .then((res) => {
                const { data } = res
                if(data.succeeded){
                  this.giftForm.gifts.push({id: data.data.name, name: data.data.name})
                }
              })
          }
        })
        .catch(error=>{
          if(error.response.status == 401){
            this.showLoginForm()
          }
        })
    },
    onLoginSubmit() {
      axios.post(`${process.env.VUE_APP_SERVER_FUR}api/account@1.0/login`, {emailOrAccount: this.loginForm.email, password: this.loginForm.password})
        .then((res) => {
          if(res.headers["access-token"]){
            localStorage.token = res.headers["access-token"]
          }
          if(res.headers["x-access-token"]){
            localStorage.refreshToken = res.headers["x-access-token"]
          }
          const { data } = res
          if(data.succeeded){
            this.loginForm.show = false
            this.login.account = this.loginForm.email.toUpperCase()
            // this.checkKey()
            this.changeLogin(true)
            this.onNavGift()
          }
          else{
            this.loginForm.hint = data.errors
          }
        })
        .catch(function (error) {
          this.loginForm.hint = error
        });
    },
    onKeySubmit() {
      this.keyForm.hint = ""
      axios.post(`${process.env.VUE_APP_SERVER_FUR}api/game-key@1.0/bind-game-key`, {key: this.keyForm.key})
        .then((res)=>{
          const { data } = res
          if(data.succeeded){
            this.keyForm.hint = "Success! Please choose your territory on the galaxy map below. You may also view your inventory in the menu on the top right corner of this page."
          }
          else if(data.statusCode == ResponseCode.HaveKey){
            this.keyForm.hint = "You have key, can't do this anymore!"
          }
          else{
            this.keyForm.hint = data.errors
          }
        })
        .catch(error=>{
          if(error.response.status == 401){
            this.showLoginForm()
          }
          else{
            this.keyForm.hint = error
          }
        })
    },
    onUpgradeKeySubmit() {
      this.giftForm.show = false
      this.upgradeKeyForm.hint = ""
      axios.post(`${process.env.VUE_APP_SERVER_FUR}api/game-key@1.0/bind-upgrade-key`, {key: this.upgradeKeyForm.key})
        .then((res)=>{
          const { data } = res
          if(data.succeeded){
            this.upgradeKeyForm.hint = "Upgrade successful! You can now reopen your inventory to see what's changed!"
          }
          else if(data.code == ResponseCode.NeedKey){
            this.showKeyForm()
          }
          else{
            this.upgradeKeyForm.hint = data.errors
          }
        })
        .catch(error =>{
          if(error.response.status == 401){
            this.showLoginForm()
          }
          else{
            this.upgradeKeyForm.hint = error
          }
        })
    },
    checkKey(){
      if(!localStorage.token) return
      axios.get(`${process.env.VUE_APP_SERVER_FUR}api/game-key@1.0/has-key`)
        .then((res)=>{
          const { data } = res
          if(data.succeeded && data.data){
            this.changeUsedKey(true)
          }
          else if (data.succeeded && !data.data){
            this.showKeyForm()
          }
        })
        .catch(error =>{
          if(error.response.status == 401){
            this.showLoginForm()
          }
        })
    },
    showLoginForm(){
      this.onNavLogout()
      this.loginForm.show = true
    },
    showKeyForm(){
      this.keyForm.show = true
    },
    onUpgradeClick(){
      this.giftForm.show=false;
      this.upgradeKeyForm.show=true;
    },
    onSendEmail(){
      this.resetForm.hint = ""
      var validateEmail = (value, callback) => {
        if (value === '') {
          callback('Email is wrong.');
        } else {
          if (value !== '') {
            var reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if(!reg.test(value)){
              callback('Email is wrong.');
            }
          }
          callback();
        }
      };
      validateEmail(this.resetForm.email, (text)=>{
        if(text == null){
          axios.post(`${process.env.VUE_APP_SERVER_FUR}api/account@1.0/send-verification-code`, {email: this.resetForm.email, type: 1})
          .then((res)=>{
            const { data } = res
            if(data.succeeded){
              this.resetForm.hint = "Email send, check it and input the code"
            }
            else{
              this.resetForm.hint = data.errors
            }
          })
          .catch(()=>{
            this.resetForm.hint =  "Error, Try again. Or cantact us."
          })
        }
        else{
          this.resetForm.hint = text;
        }
      })
    },
    onResetSubmit(){
      this.keyForm.hint = ''
      axios.post(`${process.env.VUE_APP_SERVER_FUR}api/account@1.0/reset-password`, {email: this.resetForm.email, verificationCode: this.resetForm.code, password: this.resetForm.password})
        .then((res)=>{
          const { data } = res
          console.log(data)
          if(data.succeeded){
            this.resetForm.hint = "Success. Login with your new password"
          }
          else {
            this.resetForm.hint = data.errors
          }
        })
        .catch(()=>{
          this.resetForm.hint =  "Error, Try again. Or cantact us."
        })
      }
  },
  mounted(){
    if(localStorage.token){
      axios.get(`${process.env.VUE_APP_SERVER_FUR}api/account@1.0`)
        .then((res)=>{
          const { data } = res
          if(data.succeeded){
            this.changeLogin(true)
            this.login.account = data.data.account
          }
        })
        .catch(error =>{
          if(error.response.status == 401){
            this.showLoginForm()
          }
        })
    }
  }
}
</script>

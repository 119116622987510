<template>
  <div>
    <b-container>
      <!-- 标题 -->
      <h1>Custom Event Interface</h1>

      <!-- 语言 -->
      <b-row class="justify-content-center">
        <b-col>
          <b-form-group label="Select Language" label-cols="6">
            <b-dropdown :text="evnetLang" class="justify-content-start">
              <b-dropdown-item @click="evnetLang = 'English'">English</b-dropdown-item>
              <b-dropdown-item @click="evnetLang = '简体中文'">简体中文</b-dropdown-item>
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- 事件名称 -->
      <b-row>
        <b-col>
          <b-form-group label="Event name (required)" label-cols="6">
            <b-input-group prepend="">
              <b-form-input
              type="url"
              v-model="eventName"
              placeholder="Title example : Diary of a madman"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- 事件内容 -->
      <b-row>
        <b-col>
          <b-form-group label="Event content (required)" label-cols="6">
            <b-input-group prepend="">
              <b-form-textarea type="url" v-model="evnetContent" :placeholder="test" rows="7"></b-form-textarea>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- 选项 -->
      <b-row>
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <p class="">Event options and expected rewards</p>
        </b-col>
        <b-col>
          <!-- 选项列表 -->
          <b-button-group vertical>
            <b-button v-b-modal.modal-1 v-for="option in options" :key="option.number" @click="EditEvent(option)">
              {{optionsShow[option.number]}}
            </b-button>
          </b-button-group>

          <!-- 选项编辑 -->
          <b-modal id="modal-1" title="Edit" no-close-on-backdrop no-fade @ok="EditEventOk">
            <!-- 选项名称 -->
            <b-form-group
              label="Enter Option"
              :invalid-feedback="invalidFeedback"
              :valid-feedback="validFeedback"
              :state="state"
            >
              <b-form-input
                id="input-1"
                type="text"
                v-model="optionEdit.name"
                placeholder="Example of Option A: Cannibalism is Cannibalism">
              </b-form-input>
            </b-form-group>
            <!-- 选项奖励 -->
            <b-form-group
              label="Select Reward"
              :invalid-feedback="invalidFeedbackReward"
              :valid-feedback="validFeedbackReward"
              :state="stateReward"
            >
              <b-dropdown :text="optionEdit.reward">
                <b-dropdown-item @click="optionEdit.reward = 'Reward gold to the player'">Reward gold to the player</b-dropdown-item>
                <b-dropdown-item @click="optionEdit.reward = 'Reward fuel to the player'">Reward fuel to the player</b-dropdown-item>
                <b-dropdown-item @click="optionEdit.reward = 'Take gold from the player'">Take gold from the player</b-dropdown-item>
                <b-dropdown-item @click="optionEdit.reward = 'Inflict damage to the player'">Inflict damage to the player</b-dropdown-item>
              </b-dropdown>
            </b-form-group>
            <!-- 选项回复 -->
            <b-form-group
              label="Enter Reply"
            >
              <b-form-textarea
                id="textarea"
                v-model="optionEdit.reply"
                placeholder="Enter something or not"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>
          </b-modal>
        </b-col>
      </b-row>

      <!-- 图片输入框 -->
      <b-row class="justify-content-center mt-4">
        <b-col cols="12">
          <b-form-group
            label="Custom Picture"
            label-cols="6"
            :state="stateUrl">
            <b-input-group prepend="">
              <b-form-input type="url" v-model="pictureUrl" placeholder="URL"></b-form-input>
              <b-input-group-append>
                <b-button @click="showPictureUrl = pictureUrl">Show Preview</b-button>
              </b-input-group-append>
            </b-input-group>
            <template v-slot:invalid-feedback>
              <p>
                The url for the picture is in an incorrect format. Please be sure to upload it on <b-link href="https://imgur.com/" target="_blank">imgur</b-link>.
                Once done right click on the picture and select "copy image address" do have an address ending in .jpg
              </p>
            </template>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- 图片预览 -->
      <b-row class="mt-4">
        <b-col cols="6" class="d-flex justify-content-center align-items-center">
          <p class="">Picture Preview</p>
        </b-col>
        <b-col>
          <b-img :src="showPictureUrl" fluid ></b-img>
        </b-col>
      </b-row>
      <!-- 邮箱 -->
      <b-row class="mt-4">
        <b-col>
          <b-form-group label="Mailbox (optional)" label-cols="6">
            <b-input-group prepend="">
              <b-form-input type="email" v-model="email" placeholder="Please enter a valid email address."></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <!-- 按钮 -->
      <b-row class="mt-4">
        <b-col>
          <b-button v-if="!sending" variant="primary" @click="Save">Save Event</b-button>
          <b-button v-else><b-spinner small type="grow"></b-spinner>Saving...</b-button>
          <p>{{hint}}</p>
        </b-col>
      </b-row>

    </b-container>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data(){
    return {
      pictureUrl: "",
      showPictureUrl: "https://i.imgur.com/DyLN48U.png",
      options:[
        {name: "", reward: "", number: 0, reply: ""},
        {name: "", reward: "", number: 1, reply: ""},
        {name: "", reward: "", number: 2, reply: ""},
        {name: "", reward: "", number: 3, reply: ""},
      ],
      eventName: "",
      evnetContent: "",
      evnetLang: "English",
      optionEdit:{
        number: 0,
        name: "",
        reward: "",
        reply: "",
      },
      hint:"",
      test:`Content example:
It's my big bro who eats people!
I am the brother of a cannibal!
I also have been eaten, but I remain
the brother of a cannibal!
What is cannibalism?`,
      sending: false,
      email: "",
    }
  },
  computed: {
    optionsShow(){
      var show = [];
      for(var i = 0; i<this.options.length; i++)
      {
        if(this.options[i].name.length == 0){
          show[i] = "+";
        }
        else{
          show[i] = this.options[i].name+" "+this.options[i].reward;
        }
      }
      return show;
    },
    state() {
      return this.optionEdit.name.length >= 4 ? true : false
    },
    invalidFeedback() {
      if (this.optionEdit.name.length > 4) {
        return ''
      } else if (this.optionEdit.name.length > 0) {
        return 'Enter at least 4 characters'
      } else {
        return 'Please enter something'
      }
    },
    validFeedback() {
      return this.state === true ? '' : ''
    },
    stateReward() {
      return this.optionEdit.reward.length > 0 ? true : false
    },
    invalidFeedbackReward() {
      if (this.optionEdit.reward.length > 0) {
        return ''
      } else {
        return 'Please select Reward'
      }
    },
    validFeedbackReward() {
      return this.stateReward === true ? '' : ''
    },
    stateUrl(){
      let state = this.pictureUrl.startsWith("https://i.imgur.com/");
      return state
    }
  },
  methods:{
    EditEvent(option){
      this.optionEdit.name = option.name;
      this.optionEdit.reward = option.reward;
      this.optionEdit.reply = option.reply;
      this.optionEdit.number = option.number;
    },
    EditEventOk(){
      if(!this.state || !this.stateReward) return;
      this.options[this.optionEdit.number].name = this.optionEdit.name
      this.options[this.optionEdit.number].reward = this.optionEdit.reward
      this.options[this.optionEdit.number].reply = this.optionEdit.reply
      this.optionEdit.name = ""
      this.optionEdit.reward = ""
      this.optionEdit.reply = ""
    },
    Save(){
      this.sending = true
      axios.post(`${process.env.VUE_APP_SERVER_FUR}api/event@1.0`, {
            "lang": this.evnetLang,
            "name": this.eventName,
            "content": this.evnetContent,
            "pictureUrl": this.pictureUrl,
            "options": this.options,
            "email": this.email,
          },
          {
            validateStatus: function (status) {
              return status < 500; // Resolve only if the status code is less than 500
            }
          }
          )
          .then((res) => {
            this.sending = false
            // 限制频率
            if(res.status == 429){
              this.$bvToast.toast(`Save too frequently, please try again in 1 minute.`, {
                title: 'Hint',
                autoHideDelay: 5000,
                toaster: 'b-toaster-bottom-right'
              })
            }
            else{
              const { data } = res
              if(data.code == 1001){
                console.log("Error")
              }
              this.$bvToast.toast(`Save Success!`, {
                title: 'Hint',
                autoHideDelay: 5000,
                toaster: 'b-toaster-bottom-right'
              })
            }
          })
    },
    Close(){
      window.opener=null;
      window.close();
      window.open('',self);
    },
  }
}
</script>

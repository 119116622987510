export default {
  gamePack:{
    title: 'Active your keys in Inventory',
  },
  domain: {
    title: 'Claim Your Territory in the Universe!',
    subtitle: `There is an important reason to become a backer as early as possible: to secure your chunk of space in the universe.
    The galaxy is divided into a number of Sectors. Each Sector is in turn divided into a number of Space Volumes. Once you gain ownership of a Space Volume, you can populate it with your own structures, vendors, and decorations.
    The closer a Space Volume is to the Solar System (the most populated system of the galaxy), the more player traffic it receives. By having a Space Volume as close to the Solar System as possible, you increase the chances of other players visiting your turf and enjoying your creations.
    Having a Space Volume is also a requirement for creating guilds. Space Volumes will become tradable between players at a later time.
    Note: this is not a Pay-to-Win feature. Having a Space Volume does not impact your power.`,
    confirm: {
      title: 'Please fill the following form to your aquire construction site',
      des: `Only your game key is needed, if you wish to receive news and update about the game you can enter your email address and/or your phone number. `,
      email: 'Email',
      phone: 'Phone',
      keycode: 'Key',
    },
    submit: 'Submit',
    domains: [
      {},
      {
        name: 'Sector 3KL',
        desc: `The richest sector in the universe. Most rich and influencial people reside here, as it is the closest region to the core of the Solar system.
        This sector's name stems from the distance separating it from the core of the solar system, 3000 light years. This sector is prosperous as it gathers the most essential energy from the solar system's core
        `,
      },
      {
        name: 'Matrix sector, "Number two"',
        desc: `This region is the second closest to the core of the universe. For this reason it is the home of less powerful people that are unable to access Sector 3KL. For this reason its inhabittants and the sector are often reffered as "Number two" in a derogatory way.
        Its trajectory does not follow a set path and tends to approach the core of the universe, which is yet another reason why it is called Number two.
        `,
      },
      {
        name: 'DunBanMa compound sector',
        desc: `The region is home to many human life-suited planets, including our home planet Earth, making it an ideal location for power. All the aspiring Machiavellists aquire a planet here as a backup.`,
      },
      {
        name: 'Best view sector, touristic sector',
        desc: `The most beautiful sector in the universe, home to most incredible of natural spectacles. The region offers year long cruises showcasing the best our universe has to show.
        Popular tourist sector that attracts all sorts of business, handcrafting and service industries.
        `,
      },
      {
        name: 'Sector 681 Planned area, energy',
        desc: `Sector 681 is the product of a plan set by an ancient power called the Titans. It contains 999 districts, each serving a specific purpose.
        Sector 681 has a plethora of gaseous and liquid planets, all with abundant resources transformable into energy.
        `,
      },
      {
        name: 'sector 934 Planned area, Transportation',
        desc: `Sector 934 is also the result of the Titans' will. Finding itself in the center of civilization and the most remote locations, it became a transport hub. It has a large number of transit stations, docks, IJS (Interstellar Jump Station) transit equipment and cargo ships.
        Being the main transit sector, its population enjoys an insane amount of products coming from the whole universe. Its residents also enjoy the unparalled convenience of speed and interstellar transportation to go wherever their heart sets to.
        `,
      },
      {
        name: 'Main unregulated sector:',
        desc: `The most stable unregulated region near the edge of the universe. Dominated by the most immoral yet profitable businesses such as pornography,
        gambling, drugs, slave trade, mercenaries, killers, (and game developpers), it can be used as a base to start your empire if you are willing to build it on a pile of corpses.
        `,
      },
      {
        name: 'Unregulated sector :',
        desc: `The most marginal, chaotic region of the known universe. It is the home of all sorts of races living day by day and is completely unregulated.
        While some have tried to instore power, none lasted. No laws are respected, including the laws of the universe, who seems to have given up on this region.
        `,
      },
    ],
  },
  navbar: {
    social: "Social",
    account: "My account",
    email: "Username Or Email",
    password: "Password",
    login: "Login",
    loginHint: "Please log into your Multiverse account. You can create a Multiverse account in the main menu of the game.",
    passwordError: "That username and password combination was not found.",
    gift: "Inventory",
    logout: "Logout",
    inputKey: "Input Key",
  },
  key: {
    title: "login successful",
    des: "Please enter your CD key!",
    input: "input your cdkey",
  },
  gift: {
    title: "INVENTORY",
    des: "Here is the list of everything you unlocked in EFAS so far!"
  }
};

<template>
  <div id="player">
    <div id="header">
      <h5>{{account}}</h5>
      <h5>Play Time: {{playerTimeCount}} H</h5>
    </div>
    <div id="gift" v-if="giftForm.show">
      <div class="p-4">
        <h3>{{ $t("gift.title") }}</h3>
        <h5 v-if="this.giftForm.gifts.length > 0">{{ $t("gift.des") }}</h5>
        <b-container v-if="this.giftForm.gifts.length > 0">
          <b-row>
            <b-col v-for="gift in giftForm.gifts" :key="gift.id" class="bg-dark"  lg="4">
              <h6 class="d-inline">{{gift.name}}</h6>
            </b-col>
          </b-row>
        </b-container>
        <div  v-if="this.giftForm.gifts.length == 0">
          <h5>{{account}} has purchased one of the early backer packs, but hasn't claimed their rewards yet.</h5>
          <h5>If you are {{account}}, please login (using the button on the top right), and enter your activation code to claim your items.</h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      account: "",
      playerTimeCount: 0,
      giftForm: {
        show: false,
        gifts: null,
      },
    }
  },
  mounted(){
    document.querySelector('body').setAttribute('style', 'background-color:#2b2b2b')
    axios.post(`${process.env.VUE_APP_SERVER}findMultiverse`, {account: this.$route.query.account})
        .then((res) => {
          const { data } = res
          if(data.code == 1007){
            console.log("need do something")
          }
          else{
            this.account = data.account
            this.playerTimeCount = Math.ceil(data.playerTimeCount/3600)
            this.giftForm.show = true
            this.giftForm.gifts = new Array();
            if(data.playerGifts){
              data.playerGifts.forEach(element => {
                this.giftForm.gifts.push({id: element.id, name: element.name})
              });
            }
            if(data.territory){
              this.giftForm.gifts.push({id: data.territory.name, name: data.territory.name})
            }
          }
        })
  },
  beforeDestroy() {
    document.querySelector('body').removeAttribute('style')
  },
  methods:{
    onGift() {

    },
  },
}
</script>


<style lang="scss">
#player {
  background-color: $custom-bg-color;
  color: white;
  white-space: pre-line;
  #header {
    height: 10rem;
    padding-top: 3rem;
  }
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Domain from '../views/Domain/Domain.vue'
import Friend from '../views/Friend/Friend.vue'
import Player from '../views/Player/Player.vue'
import Event from '../views/Event/Event.vue'
import GamePack from '../views/GamePack/GamePack.vue'


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'GamePack',
    component: GamePack,
  },
  {
    path: '/domain',
    name: 'Domain',
    component: Domain,
  },
  {
    path: '/friend',
    name: 'Friend',
    component: Friend,
  },
  {
    path: '/Player',
    name: 'Player',
    component: Player,
  },
  {
    path: '/Event',
    name: 'Event',
    component: Event,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

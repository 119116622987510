import Vue from 'vue';
import Vuex from 'vuex';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

// 国际化 vue-i18n
import { i18n } from './setup/i18n-setup';

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    isLogin: false,
    isUsedKey: false,
  },
  mutations: {
    changeLogin(state, isLogin){
      state.isLogin = isLogin
    },
    changeUsedKey(state, isUsedKey){
      state.isUsedKey = isUsedKey
    }
  }
})

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  render: (h) => h(App),
  store,
}).$mount('#app');

// http request 拦截器
axios.interceptors.request.use(
  config => {
    if (localStorage.token) { //判断token是否存在
      config.headers.Authorization = "Bearer "+localStorage.token;  //将token设置成请求头
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

// http response 拦截器
axios.interceptors.response.use(
  response => {
    // if (response.data.errno === 999) {
    //   router.replace('/');
    //   console.log("token过期");
    // }
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);
